import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Swiper, Slide, Colors, ArrowNavigation, DefaultNavigation, Section } from '@class101/ui';
import { PlayGroundBanner } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "swiper"
    }}>{`Swiper`}</h1>
    <p><a parentName="p" {...{
        "href": "https://idangero.us/swiper/api/#events"
      }}>{`swiper api: Events`}</a></p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Swiper} mdxType="Props" />
    <h2 {...{
      "id": "default-swiper"
    }}>{`Default Swiper`}</h2>
    <Playground __position={1} __code={'<Swiper>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red100}>배너1</PlayGroundBanner>\n  </Slide>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red200}>배너2</PlayGroundBanner>\n  </Slide>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red300}>배너3</PlayGroundBanner>\n  </Slide>\n</Swiper>\n<Swiper navigationChildren={<ArrowNavigation />}>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red100}>배너1</PlayGroundBanner>\n  </Slide>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red200}>배너2</PlayGroundBanner>\n  </Slide>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red300}>배너3</PlayGroundBanner>\n  </Slide>\n</Swiper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Swiper,
      Slide,
      Colors,
      ArrowNavigation,
      DefaultNavigation,
      Section,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Swiper mdxType="Swiper">
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red100} mdxType="PlayGroundBanner">
        배너1
      </PlayGroundBanner>
    </Slide>
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red200} mdxType="PlayGroundBanner">
        배너2
      </PlayGroundBanner>
    </Slide>
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red300} mdxType="PlayGroundBanner">
        배너3
      </PlayGroundBanner>
    </Slide>
  </Swiper>
  <Swiper navigationChildren={<ArrowNavigation mdxType="ArrowNavigation" />} mdxType="Swiper">
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red100} mdxType="PlayGroundBanner">
        배너1
      </PlayGroundBanner>
    </Slide>
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red200} mdxType="PlayGroundBanner">
        배너2
      </PlayGroundBanner>
    </Slide>
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red300} mdxType="PlayGroundBanner">
        배너3
      </PlayGroundBanner>
    </Slide>
  </Swiper>
    </Playground>
    <h2 {...{
      "id": "fade-effect-swiper"
    }}>{`Fade Effect Swiper`}</h2>
    <Playground __position={2} __code={'<Swiper effect=\"fade\">\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.red100}>배너1</PlayGroundBanner>\n  </Slide>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.blue200}>\n      배너2\n    </PlayGroundBanner>\n  </Slide>\n  <Slide>\n    <PlayGroundBanner backgroundColor={Colors.orange300}>\n      배너3\n    </PlayGroundBanner>\n  </Slide>\n</Swiper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Swiper,
      Slide,
      Colors,
      ArrowNavigation,
      DefaultNavigation,
      Section,
      PlayGroundBanner,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Swiper effect="fade" mdxType="Swiper">
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.red100} mdxType="PlayGroundBanner">
        배너1
      </PlayGroundBanner>
    </Slide>
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.blue200} mdxType="PlayGroundBanner">
        배너2
      </PlayGroundBanner>
    </Slide>
    <Slide mdxType="Slide">
      <PlayGroundBanner backgroundColor={Colors.orange300} mdxType="PlayGroundBanner">
        배너3
      </PlayGroundBanner>
    </Slide>
  </Swiper>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      